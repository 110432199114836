import {doppeActionTypeDefineMetadata} from '../../client-server-common/doppe-action-types/doppe-action-type';
import {CurrencyCode, immerProduce, PartiallyOptional} from '@wix/devzai-utils-common';
import {
    DoppeCheckoutActionSettings,
    DoppeCheckoutSettingsWithBindings,
    getDefaultDoppeCheckoutSettingsForAction,
    prepareCheckoutSettingsForViewer
} from '../../client-server-common/types/doppe-checkout-action-settings';
import {RichTextContent} from '@wix/devzai-utils-react';
import {WixImageResource, wixMediaResourceReplaceExternalImage} from '@wix/devzai-common-wix';
import {
    DoppeAutomationTriggers,
    DoppeHideableValue,
    doppeHideableValueCreateHidden,
    doppePremiumFeatureIsDisabled,
    DoppePremiumFeatures,
} from '../../client-server-common';

export namespace DoppeActionTypeDonate {
    export interface ActionSettings extends
        DoppeCheckoutActionSettings,
        DeprecatedProps
    {
        amountOptions: number[];

        amountSelectionButtonText?: string;
        amountSelectionTitle: DoppeHideableValue<string>;
        description: DoppeHideableValue<RichTextContent>;
        images: WixImageResource[] | null;
        showCustomAmount: boolean;
        showMessageInput: boolean;
        messageInputTitle: string;
        previewCheckoutButtonText: string;
        currencyCode: CurrencyCode;
    }

    export interface DeprecatedProps {
        showEmailSubscriptionConsent?: boolean;
        showEmailSubscriptionConsentCheckbox?: boolean;
        emailSubscriptionConsentCustomText?: DoppeHideableValue<string>;
    }
}

export const doppeActionTypeDonateMetadata = doppeActionTypeDefineMetadata<DoppeActionTypeDonate.ActionSettings>({
    id: 'co.hopp.action/donate',
    resolveActionSettings: (action) => {
        const {
            checkoutSettings,
            ...restSettings
        } = action.settings;

        return {
            amountOptions: [],
            currencyCode: 'USD',
            amountSelectionButtonText: '',
            amountSelectionTitle: '',
            showCustomAmount: false,
            showMessageInput: false,
            previewCheckoutButtonText: restSettings.amountSelectionButtonText ?? '',
            images: null,
            description: doppeHideableValueCreateHidden(''),
            messageInputTitle: '',
            ...restSettings,
            checkoutSettings: getDefaultDoppeCheckoutSettingsForAction({
                showConsent: restSettings.showEmailSubscriptionConsent,
                withEmailNotification: true,
                showConsentCheckbox: restSettings.showEmailSubscriptionConsentCheckbox,
                customConsentText: restSettings.emailSubscriptionConsentCustomText,
                ...checkoutSettings as PartiallyOptional<DoppeCheckoutSettingsWithBindings, 'customConsentText' | 'showConsentCheckbox' | 'withEmailNotification' | 'showConsent'>
            }),
        };
    },
    prepareForViewer: actionSettings => {
        prepareCheckoutSettingsForViewer(actionSettings);
    },
    isWidgetOpener: true,
    canRenderInPage: true,
    skipReportingOnGoalAchievedOnActionButtonClick: true,
    isPaymentAction: true,
    getSupportedAutomationTrigger: () => DoppeAutomationTriggers.PaymentDone,
    isFormAction: false,
    isPremiumAction: (premiumFeaturesStatus) => {
        return doppePremiumFeatureIsDisabled(premiumFeaturesStatus, DoppePremiumFeatures.creators_monetization_actions)
    },
    resolveUsedMedia: actionSettings => {
        return actionSettings.images ?? [];
    },
    resolveMainMedia: actionSettings => {
        return actionSettings.images ? actionSettings.images[0] ?? null : null;
    },
    replaceExternalImages: (actionSettings, externalImagesUrlToImageResourceMap) => {
        return immerProduce(actionSettings, actionSettings => {
            if (actionSettings.images) {
                actionSettings.images = actionSettings.images.map(
                    image => wixMediaResourceReplaceExternalImage(image, externalImagesUrlToImageResourceMap))
            }
        })
    },
});