import {doppeActionTypeDefineMetadata} from '../../client-server-common/doppe-action-types/doppe-action-type';
import {
    arrayFlatten,
    arrayRemoveNullValues,
    immerProduce,
    iterableMapToArray,
    PartiallyOptional
} from '@wix/devzai-utils-common';
import {DoppeActionTypeCoupon} from '../doppe-action-type-coupon/doppe-action-type-coupon';
import {wixMediaResourceReplaceExternalImage} from '@wix/devzai-common-wix';
import {
    convertCommonListSettingToDefaultDoppeActionCtaButtonSettingsFromListAction,
    doppeHideableValueGetVisibleValue,
    doppeHideableValueIsVisibleAndNotEqualValue,
    doppeListActionGetDefaultListSettings, doppeListActionGetDefaultSearchListSettings,
    doppeListActionGetItemClickBehaviourDefaultListSettings,
    doppeListActionGetMainDetailsDefaultSettings,
    doppeListActionListItemGetDefaultListItemSettingsMigration,
    DoppeListActionSettings, doppeListActionSettingsFilterHiddenListItemsFromActionView,
    doppeListActionWithMainDetailsGetMainImage,
    doppeListActionWithMainDetailsReplaceMainImage
} from '../../client-server-common';
import {
    doppeExternalCouponProviderResolveBusinessEntityReference
} from '../../client-server-common/types/doppe-external-coupon';
import {doppeHideableValueCreateHidden} from '../../client-server-common/types/doppe-hideable-value';
import {DoppeActionTypeCouponsListViewerLang} from './doppe-action-type-coupons-list-viewer.lang';
import {
    doppeActionCtaButtonResetSettingsForLock,
    doppeActionCtaButtonTypeResolveURL
} from '../../client-server-common/types/doppe-action-cta-button';
import {
    doppeActionCtaButtonTypeGetDefaultCtaButtonSettingsMigration,
    getDefaultDoppeActionCtaButtonSettings
} from '../../client-server-common/types/doppe-action-cta-button-settings';
import {
    doppeDtoActionSettingsRemoveInnerCtaContentForLockedAction
} from '../../client-server-common/types/doppe-dto-action';


export namespace DoppeActionTypeCouponsList {

    export interface CouponSettings extends
        DoppeListActionSettings.ListItemBaseSettings,
        DoppeActionTypeCoupon.ActionSettings {
        title: string;
    }

    export interface ActionSettings extends
        DeprecatedFields,
        DoppeListActionSettings.WithSearchSettings,
        DoppeListActionSettings<CouponSettings>,
        DoppeListActionSettings.WithCtaButton,
        DoppeListActionSettings.WithItemClickBehaviour,
        DoppeListActionSettings.WithMainDetails {
        previewShowTitle: boolean;
        previewShowDescription: boolean;
        previewCouponButtonText: string;
    }

    export interface DeprecatedFields {
        coupons?: CouponSettings[];
    }
}


export const doppeActionTypeCouponsList = doppeActionTypeDefineMetadata<DoppeActionTypeCouponsList.ActionSettings>({
    id: 'io.bymo.action/coupons-list',
    resolveActionSettings: action => {

        const {
            listDataItems = [],
            ctaButton,
            listSearchSettings,
            ...restSettings
        } = action.settings;

        return {
            previewShowDescription: true,
            previewShowTitle: true,
            previewCouponButtonText: DoppeActionTypeCouponsListViewerLang.defaultTexts.couponButtonText,
            ...doppeListActionGetDefaultSearchListSettings(action.settings),
            ...doppeListActionGetDefaultListSettings({
                listDataItems: listDataItems.map(coupon => {

                    const {
                        couponButtonText,
                        showCouponButtonLink,
                        couponPageLink,
                        ctaButton,
                        ...restCouponSettings
                    } = coupon;

                    return {
                        couponProvider: null,
                        couponProviderId: null,
                        ribbon: doppeHideableValueCreateHidden(''),
                        ...getDefaultDoppeActionCtaButtonSettings({
                            buttonText: couponButtonText,
                            showButton: showCouponButtonLink,
                            url: couponPageLink
                        }),
                        ...doppeActionCtaButtonTypeGetDefaultCtaButtonSettingsMigration({
                            hasSourceURL: false,
                            ctaButton: ctaButton,
                        }),
                        ...doppeListActionListItemGetDefaultListItemSettingsMigration(coupon.id),
                        ...restCouponSettings as PartiallyOptional<DoppeActionTypeCouponsList.CouponSettings, 'couponProvider' | 'couponProviderId' | 'ribbon' | 'ctaButton'>,

                    }
                }),
            }),
            ...doppeListActionGetItemClickBehaviourDefaultListSettings(),
            ...doppeListActionGetMainDetailsDefaultSettings(),
            ...convertCommonListSettingToDefaultDoppeActionCtaButtonSettingsFromListAction(action.settings),
            ...doppeActionCtaButtonTypeGetDefaultCtaButtonSettingsMigration({
                ctaButton: ctaButton,
                hasSourceURL: false
            }),
            ...restSettings,

        }
    },
    isWidgetOpener: true,
    canRenderInPage: true,
    isListItemLinkOpener: false,
    supportsListLayout: true,
    skipReportingOnGoalAchievedOnActionButtonClick: true,
    resolveUsedMedia: actionSettings => {
        return arrayRemoveNullValues([...arrayFlatten(actionSettings.listDataItems.map(itemSettings => {
            return [itemSettings.couponImage]
        })), doppeHideableValueIsVisibleAndNotEqualValue(actionSettings.mainImage, null) ? actionSettings.mainImage : null]);
    },
    resolveMainMedia: actionSettings => {
        return doppeListActionWithMainDetailsGetMainImage(actionSettings, actionSettings.listDataItems[0]?.couponImage ?? null);
    },
    resolveMainLink: actionSettings => {
        return doppeActionCtaButtonTypeResolveURL(actionSettings.ctaButton.settings) ?? null;
    },
    resolveListItemsCount: actionSettings => {
        return doppeListActionSettingsFilterHiddenListItemsFromActionView(actionSettings.listDataItems).length;
    },
    resolveCouponDataFromListItem: listItem => {
        return {
            showCouponCode: doppeHideableValueIsVisibleAndNotEqualValue(listItem.couponCode, ''),
            couponCode: doppeHideableValueGetVisibleValue(listItem.couponCode, ''),
            couponDescription: '',
            couponTitle: doppeHideableValueGetVisibleValue(listItem.title, ''),
        }
    },
    resolveListItems: action => {
        const actionSettings = action.settings;
        return actionSettings.listDataItems.map(item => {
            return {
                title: item.title,
                action: action,
                couponCode: item.coupon,
                description: item.description,
                image: item.couponImage,
                itemId: item.id,
                ribbon: item.ribbon,
                url: item.couponPageLink,
                enabled: item.enabled,
                deleted: item.deleted,
                displayInSearch: item.displayInSearch,
            }
        });
    },
    resolveListSearchSettings: actionSettings => {
        return actionSettings.listSearchSettings;
    },
    resolveUsedBusinessEntities: actionSettings => {
        return iterableMapToArray(actionSettings.listDataItems, (coupon, skip) => {
            return coupon.couponProvider !== null && coupon.couponProviderId !== null ?
                doppeExternalCouponProviderResolveBusinessEntityReference(
                    coupon.couponProvider,
                    coupon.couponProviderId
                ) : skip
        });
    },
    replaceExternalImages: (actionSettings, externalImagesUrlToImageResourceMap) => {
        return immerProduce(actionSettings, actionSettings => {
            for (const item of actionSettings.listDataItems) {
                if (item.couponImage) {
                    item.couponImage =
                        wixMediaResourceReplaceExternalImage(item.couponImage, externalImagesUrlToImageResourceMap)
                }
            }

            doppeListActionWithMainDetailsReplaceMainImage(actionSettings, externalImagesUrlToImageResourceMap);
        })
    },
    prepareForViewer: (actionSettings, options) => {
        doppeDtoActionSettingsRemoveInnerCtaContentForLockedAction(actionSettings, actionSettings => {
            actionSettings.listDataItems.forEach(itemSettings => {
                doppeActionCtaButtonResetSettingsForLock(itemSettings);
            });

            doppeActionCtaButtonResetSettingsForLock(actionSettings);
        }, options);
    },
    hasNoLockInnerCTA: true,
});