import {doppeActionTypeDefineMetadata} from '../../client-server-common/doppe-action-types/doppe-action-type';
import {
    convertDoppeWixProductToProductSettings,
    DoppeActionTypeProduct,
    populateProductActionSettingsDefaultTexts
} from '../doppe-action-type-product/doppe-action-type-product';
import {
    arrayFlatten,
    arrayRemoveNullValues,
    asArray,
    immerProduce,
    iterableMapToArray,
    PartiallyOptional
} from '@wix/devzai-utils-common';
import {wixMediaResourceReplaceExternalImage} from '@wix/devzai-common-wix';
import {
    convertCommonListSettingToDefaultDoppeActionCtaButtonSettingsFromListAction,
    DoppeActionLayout,
    DoppeDtoStoreProduct,
    doppeExternalProductProviderResolveBusinessEntityReference,
    doppeHideableValueCreateHidden,
    doppeHideableValueEnsureHidden,
    doppeHideableValueGetVisibleValue,
    doppeHideableValueIsHidden,
    doppeHideableValueIsVisibleAndNotEqualValue,
    doppeHideableValueModifyValue,
    doppeListActionGetDefaultListSettings, doppeListActionGetDefaultSearchListSettings,
    doppeListActionGetItemClickBehaviourDefaultListSettings,
    doppeListActionGetMainDetailsDefaultSettings,
    doppeListActionGetWithExternalDataSourceDefaultListSettings,
    doppeListActionListItemGetDefaultListItemSettingsMigration,
    DoppeListActionSettings, doppeListActionSettingsFilterHiddenListItemsFromActionView,
    doppeListActionWithMainDetailsGetMainImage,
    doppeListActionWithMainDetailsReplaceMainImage,
    DoppeProductsExternalDataSource,
    doppeProductsExternalDataSourceResolveBusinessEntityReferences
} from '../../client-server-common';
import {
    doppeDtoActionSettingsRemoveInnerCtaContentForLockedAction
} from '../../client-server-common/types/doppe-dto-action';
import {DoppeAppPageContentLocales} from '../../client/doppe-app-locales/doppe-app-page-content-locales';
import {
    doppeActionCtaButtonResetSettingsForLock,
    doppeActionCtaButtonTypeResolveURL
} from '../../client-server-common/types/doppe-action-cta-button';
import {
    doppeActionCtaButtonTypeGetDefaultCtaButtonSettingsMigration,
    getDefaultDoppeActionCtaButtonSettings
} from '../../client-server-common/types/doppe-action-cta-button-settings';
import {
    doppeActionSettingsCtaDefaultSettings
} from '../../client/doppe-action-types-client-common/doppe-action-settings-cta-client-common';

export namespace DoppeActionTypeProducts {

    export interface ProductSettings extends
        DoppeListActionSettings.ListItemBaseSettings,
        DoppeActionTypeProduct.ActionSettings {
        productTitle: string;
    }

    export interface ActionSettings extends
        DeprecatedFields,
        DoppeListActionSettings<ProductSettings>,
        DoppeListActionSettings.WithSearchSettings,
        DoppeListActionSettings.WithMainDetails,
        DoppeListActionSettings.WithCtaButton,
        DoppeListActionSettings.WithItemClickBehaviour,
        DoppeListActionSettings.WithExternalDataSourceSupport<DoppeProductsExternalDataSource> {
        itemShowPrice: boolean;
        previewShowTitle: boolean;
        previewShowDescription: boolean;
        previewShowPrice: boolean;
        previewShowCoupon: boolean;
        previewProductButtonText: string;
    }

    export interface DeprecatedFields {
        products?: ProductSettings[];
    }
}

export const doppeActionTypeProducts = doppeActionTypeDefineMetadata<DoppeActionTypeProducts.ActionSettings>({
    id: 'io.bymo.action/products',
    resolveActionSettings: action => {

        const {
            listDataItems = [],
            ctaButton,
            listSearchSettings,
            ...restSettings
        } = action.settings;

        return {
            itemShowPrice: true,
            itemShowDate: false,
            previewShowCoupon: true,
            previewShowPrice: true,
            previewShowDescription: true,
            previewShowTitle: true,
            previewProductButtonText: 'Buy Now',
            ...doppeListActionGetWithExternalDataSourceDefaultListSettings(),
            ...doppeListActionGetDefaultSearchListSettings(action.settings),
            ...doppeListActionGetDefaultListSettings({
                layout: DoppeActionLayout.Column,
                listDataItems: listDataItems.map(product => {

                    const {
                        productButtonText,
                        ctaButton,
                        ...restProduct
                    } = product;

                    return {
                        productProvider: null,
                        productProviderId: null,
                        ribbon: doppeHideableValueCreateHidden('SALE'),
                        ...getDefaultDoppeActionCtaButtonSettings({
                            buttonText: productButtonText,
                            showButton: true
                        }),
                        ...doppeActionCtaButtonTypeGetDefaultCtaButtonSettingsMigration({
                            hasSourceURL: true,
                            ctaButton: ctaButton,
                        }),
                        ...doppeListActionListItemGetDefaultListItemSettingsMigration(product.id),
                        ...restProduct as PartiallyOptional<DoppeActionTypeProducts.ProductSettings, 'productProvider' | 'productProviderId' | 'ribbon' | 'ctaButton'>
                    }
                }),
            }),
            ...doppeListActionGetItemClickBehaviourDefaultListSettings(),
            ...doppeListActionGetMainDetailsDefaultSettings({
                defaultTitle: ''
            }),
            ...convertCommonListSettingToDefaultDoppeActionCtaButtonSettingsFromListAction(action.settings),
            ...doppeActionCtaButtonTypeGetDefaultCtaButtonSettingsMigration({
                ctaButton: ctaButton,
                hasSourceURL: false
            }),
            ...restSettings
        }
    },
    isWidgetOpener: true,
    canRenderInPage: true,
    skipReportingOnGoalAchievedOnActionButtonClick: true,
    isListItemLinkOpener: true,
    supportsListLayout: true,
    resolveUsedMedia: actionSettings => {
        return arrayRemoveNullValues([...arrayFlatten(iterableMapToArray(actionSettings.listDataItems, (product, skip) => {
            return product.productImage ? asArray(product.productImage) : skip
        })), doppeHideableValueIsVisibleAndNotEqualValue(actionSettings.mainImage, null) ? actionSettings.mainImage : null]);
    },
    resolveMainMedia: actionSettings => {
        const productImage = actionSettings.listDataItems[0]?.productImage ? asArray(actionSettings.listDataItems[0]?.productImage)[0] ?? null : null;
        return doppeListActionWithMainDetailsGetMainImage(actionSettings, productImage);
    },
    resolveMainLink: actionSettings => {
        return doppeActionCtaButtonTypeResolveURL(actionSettings.ctaButton.settings) ?? null;
    },
    prepareForViewer: (actionSettings, options) => {
        doppeDtoActionSettingsRemoveInnerCtaContentForLockedAction(actionSettings, actionSettings => {
            actionSettings.listDataItems.forEach(itemSettings => {
                itemSettings.productPageLink = ''
                doppeActionCtaButtonResetSettingsForLock(itemSettings);
            });

            doppeActionCtaButtonResetSettingsForLock(actionSettings);
        }, options);
    },
    isConnectedToExternalDataSource: actionSettings => {
        return !!actionSettings.externalDataSource
    },
    resolveUsedBusinessEntities: actionSettings => {
        const externalDataSource = actionSettings.externalDataSource;
        if (externalDataSource) {
            return [
                doppeProductsExternalDataSourceResolveBusinessEntityReferences(externalDataSource)
            ];
        } else {
            return iterableMapToArray(actionSettings.listDataItems, (product, skip) => {
                return product.productProvider !== null && product.productProviderId !== null ?
                    doppeExternalProductProviderResolveBusinessEntityReference(
                        product.productProvider,
                        product.productProviderId
                    ) : skip
            });
        }
    },
    resolveListItemsCount: actionSettings => {
        return actionSettings.externalDataSource ? actionSettings.externalDataSourceItemsCount : doppeListActionSettingsFilterHiddenListItemsFromActionView(actionSettings.listDataItems).length;
    },
    resolveCouponDataFromListItem: listItem => {
        return {
            showCouponCode: doppeHideableValueIsVisibleAndNotEqualValue(listItem.couponCode, ''),
            couponCode: doppeHideableValueGetVisibleValue(listItem.couponCode, ''),
            couponDescription: '',
            couponTitle: doppeHideableValueGetVisibleValue(listItem.title, ''),
        }
    },
    resolveListItems: action => {
        const actionSettings = action.settings;
        return actionSettings.listDataItems.map(item => {
            return {
                title: item.productTitle,
                action: action,
                couponCode: item.couponCode,
                description: item.description,
                image: item.productImage ? asArray(item.productImage)[0] : null,
                itemId: item.id,
                ribbon: item.ribbon,
                url: item.productPageLink,
                enabled: item.enabled,
                deleted: item.deleted,
                displayInSearch: item.displayInSearch,
            }
        });
    },
    resolveListSearchSettings: actionSettings => {
        return actionSettings.listSearchSettings;
    },
    replaceExternalImages: (actionSettings, externalImagesUrlToImageResourceMap) => {
        return immerProduce(actionSettings, actionSettings => {
            for (const item of actionSettings.listDataItems) {
                if (item.productImage) {
                    const images = asArray(item.productImage);
                    item.productImage = images.map(image => {
                        return wixMediaResourceReplaceExternalImage(image, externalImagesUrlToImageResourceMap)
                    });
                }
            }

            doppeListActionWithMainDetailsReplaceMainImage(actionSettings, externalImagesUrlToImageResourceMap);
        })
    },
});

export function populateProductsActionProductSettingsDefaultTexts(options: {
    product: DoppeActionTypeProducts.ProductSettings,
    pageContentLocales: DoppeAppPageContentLocales,
}) : DoppeActionTypeProducts.ProductSettings {

    const {
        pageContentLocales,
        product,
    } = options;

    const normalizedProduct = populateProductActionSettingsDefaultTexts({
        pageContentLocales: pageContentLocales,
        product: product
    })

    const defaultButtonText = doppeHideableValueModifyValue(normalizedProduct.ctaButton.text, (value) => {
        return value ?? pageContentLocales["product.actionSettings.productButtonText"]
    });

    const calculatedDefaultValue = doppeHideableValueEnsureHidden(defaultButtonText);

    return {
        ...options.product,
        ...normalizedProduct,
        productTitle: doppeHideableValueIsHidden(normalizedProduct.productTitle) ? '' : normalizedProduct.productTitle,
        ...doppeActionSettingsCtaDefaultSettings(pageContentLocales,{
            showButton: true,
            buttonText: calculatedDefaultValue
        })
    }
}

export function convertDoppeWixProductToProductsSettings(
    product: DoppeDtoStoreProduct,
): DoppeActionTypeProducts.ProductSettings {
    const productSettings = convertDoppeWixProductToProductSettings({
        product: product,
    });

    return {
        ...productSettings,
        productButtonText: doppeHideableValueCreateHidden(''),
        ...getDefaultDoppeActionCtaButtonSettings({
            showButton: true,
            buttonText: doppeHideableValueCreateHidden('')
        }),
        ...doppeListActionListItemGetDefaultListItemSettingsMigration(product.id),
    }
}