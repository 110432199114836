import {doppeActionTypeDefineMetadata} from '../../client-server-common/doppe-action-types/doppe-action-type';
import {
    arrayFlatten,
    arrayRemoveNullValues,
    immerProduce,
    iterableMapToArray,
    PartiallyOptional
} from '@wix/devzai-utils-common';
import {wixMediaResourceReplaceExternalImage} from '@wix/devzai-common-wix';
import {
    convertCommonListSettingToDefaultDoppeActionCtaButtonSettingsFromListAction,
    DoppeActionLayout,
    doppeExternalMembershipPlanProviderResolveBusinessEntityReference,
    doppeHideableValueCreateHidden,
    doppeHideableValueIsVisibleAndNotEqualValue,
    doppeListActionGetDefaultListSettings, doppeListActionGetDefaultSearchListSettings,
    doppeListActionGetItemClickBehaviourDefaultListSettings,
    doppeListActionGetMainDetailsDefaultSettings,
    doppeListActionGetWithExternalDataSourceDefaultListSettings,
    doppeListActionListItemGetDefaultListItemSettingsMigration,
    DoppeListActionSettings, doppeListActionSettingsFilterHiddenListItemsFromActionView,
    doppeListActionWithMainDetailsGetMainImage,
    doppeListActionWithMainDetailsReplaceMainImage
} from '../../client-server-common';
import {
    convertDoppeWixMembershipPlanToPlanSettings,
    DoppeActionTypeMembership
} from '../doppe-action-type-membership/doppe-action-type-membership';
import {DoppeDtoMembershipPlan} from '../../client-server-common/types/doppe-dto-membership-plan';
import {
    DoppeMembershipPlansExternalDataSource,
    doppeMembershipPlansExternalDataSourceResolveBusinessEntityReferences
} from '../../client-server-common/types/doppe-membership-plans-external-data-source';
import {
    doppeDtoActionSettingsRemoveInnerCtaContentForLockedAction
} from '../../client-server-common/types/doppe-dto-action';
import {
    doppeActionCtaButtonResetSettingsForLock,
    doppeActionCtaButtonTypeResolveURL
} from '../../client-server-common/types/doppe-action-cta-button';
import {
    doppeActionCtaButtonTypeGetDefaultCtaButtonSettingsMigration,
    getDefaultDoppeActionCtaButtonSettings
} from '../../client-server-common/types/doppe-action-cta-button-settings';


export namespace DoppeActionTypeMembershipList {

    export interface PlanSettings extends
        DoppeListActionSettings.ListItemBaseSettings,
        DoppeActionTypeMembership.ActionSettings {
        membershipTitle: string;
    }

    export interface ActionSettings extends
        DeprecatedFields,
        DoppeListActionSettings<PlanSettings>,
        DoppeListActionSettings.WithSearchSettings,
        DoppeListActionSettings.WithMainDetails,
        DoppeListActionSettings.WithCtaButton,
        DoppeListActionSettings.WithItemClickBehaviour,
        DoppeListActionSettings.WithExternalDataSourceSupport<DoppeMembershipPlansExternalDataSource> {
        itemShowPrice: boolean;
        previewShowTitle: boolean;
        previewShowDescription: boolean;
        previewShowPrice: boolean;
        previewShowCoupon: boolean;
        previewPlanButtonText: string;
    }

    export interface DeprecatedFields {
        plans?: PlanSettings[];
    }
}


export const doppeActionTypeMembershipList = doppeActionTypeDefineMetadata<DoppeActionTypeMembershipList.ActionSettings>({
    id: 'io.bymo.action/membership-list',
    resolveActionSettings: action => {

        const {
            listDataItems = [],
            ctaButton,
            listSearchSettings,
            ...restSettings
        } = action.settings;

        return {
            itemShowPrice: true,
            previewShowCoupon: true,
            previewShowPrice: true,
            previewShowDescription: true,
            previewShowTitle: true,
            previewPlanButtonText: 'Subscribe Now',
            ...doppeListActionGetWithExternalDataSourceDefaultListSettings(),
            ...doppeListActionGetDefaultSearchListSettings(action.settings),
            ...doppeListActionGetDefaultListSettings({
                layout: DoppeActionLayout.Swiper,
                listDataItems: listDataItems.map(plan => {

                    const {
                        linkButtonText,
                        ctaButton,
                        ...resetPlan
                    } = plan;

                    return {
                        membershipProvider: null,
                        membershipProviderId: null,
                        ...getDefaultDoppeActionCtaButtonSettings({
                            buttonText: linkButtonText,
                            showButton: true
                        }),
                        ...doppeActionCtaButtonTypeGetDefaultCtaButtonSettingsMigration({
                            hasSourceURL: true,
                            ctaButton: ctaButton,
                        }),
                        ...doppeListActionListItemGetDefaultListItemSettingsMigration(plan.id),
                        ...resetPlan as PartiallyOptional<DoppeActionTypeMembershipList.PlanSettings, 'membershipProvider' | 'membershipProviderId' | 'ctaButton'>
                    }
                }),
            }),
            ...doppeListActionGetItemClickBehaviourDefaultListSettings(),
            ...doppeListActionGetMainDetailsDefaultSettings({
                defaultTitle: ''
            }),
            ...convertCommonListSettingToDefaultDoppeActionCtaButtonSettingsFromListAction(action.settings),
            ...doppeActionCtaButtonTypeGetDefaultCtaButtonSettingsMigration({
                ctaButton: ctaButton,
                hasSourceURL: false
            }),
            ...restSettings
        }
    },
    prepareForViewer: (actionSettings, options) => {
        doppeDtoActionSettingsRemoveInnerCtaContentForLockedAction(actionSettings, actionSettings => {
            actionSettings.listDataItems.forEach(itemSettings => {
                itemSettings.membershipPageLink = ''
                doppeActionCtaButtonResetSettingsForLock(itemSettings);
            });

            doppeActionCtaButtonResetSettingsForLock(actionSettings);
        }, options);
    },
    isWidgetOpener: true,
    canRenderInPage: true,
    skipReportingOnGoalAchievedOnActionButtonClick: true,
    supportsListLayout: true,
    isListItemLinkOpener: true,
    resolveUsedMedia: actionSettings => {
        return arrayRemoveNullValues([...arrayFlatten(actionSettings.listDataItems.map(itemSettings => {
            return itemSettings.membershipImages ?? []
        })), doppeHideableValueIsVisibleAndNotEqualValue(actionSettings.mainImage, null) ? actionSettings.mainImage : null]);
    },
    resolveMainMedia: actionSettings => {
        return doppeListActionWithMainDetailsGetMainImage(actionSettings, actionSettings.listDataItems[0]?.membershipImages?.[0] ?? null);
    },
    resolveMainLink: actionSettings => {
        return doppeActionCtaButtonTypeResolveURL(actionSettings.ctaButton.settings) ?? null;
    },
    isConnectedToExternalDataSource: actionSettings => {
        return !!actionSettings.externalDataSource
    },
    resolveUsedBusinessEntities: actionSettings => {
        const externalDataSource = actionSettings.externalDataSource;
        if (externalDataSource) {
            return [
                doppeMembershipPlansExternalDataSourceResolveBusinessEntityReferences(externalDataSource)
            ];
        } else {
            return iterableMapToArray(actionSettings.listDataItems, (plan, skip) => {
                return plan.membershipProvider !== null && plan.membershipProviderId !== null ?
                    doppeExternalMembershipPlanProviderResolveBusinessEntityReference(
                        plan.membershipProvider,
                        plan.membershipProviderId
                    ) : skip
            });
        }
    },
    resolveListItemsCount: actionSettings => {
        return actionSettings.externalDataSource ? actionSettings.externalDataSourceItemsCount : doppeListActionSettingsFilterHiddenListItemsFromActionView(actionSettings.listDataItems).length;
    },
    resolveListItems: action => {
        const actionSettings = action.settings;
        return actionSettings.listDataItems.map(item => {
            return {
                title: item.membershipTitle,
                action: action,
                couponCode: item.couponCode,
                description: item.membershipDescription,
                image: item.membershipImages?.[0] ?? null,
                itemId: item.id,
                ribbon: item.ribbon,
                url: item.membershipPageLink,
                enabled: item.enabled,
                deleted: item.deleted,
                displayInSearch: item.displayInSearch,
            }
        });
    },
    resolveListSearchSettings: actionSettings => {
        return actionSettings.listSearchSettings;
    },
    replaceExternalImages: (actionSettings, externalImagesUrlToImageResourceMap) => {
        return immerProduce(actionSettings, actionSettings => {
            for (const plan of actionSettings.listDataItems) {
                if (plan.membershipImages) {
                    plan.membershipImages = plan.membershipImages.map(
                        image => wixMediaResourceReplaceExternalImage(image, externalImagesUrlToImageResourceMap))
                }
            }

            doppeListActionWithMainDetailsReplaceMainImage(actionSettings, externalImagesUrlToImageResourceMap);

        })
    },
});

export function convertDoppeWixMembershipPlanToPlansSettings(
    plan: DoppeDtoMembershipPlan,
    websiteUrl?: string,
): DoppeActionTypeMembershipList.PlanSettings {
    return {
        ...convertDoppeWixMembershipPlanToPlanSettings(plan, websiteUrl),
        ...doppeListActionListItemGetDefaultListItemSettingsMigration(plan.id),
        membershipTitle: plan?.name ?? '',
        linkButtonText: doppeHideableValueCreateHidden(''),
        ...getDefaultDoppeActionCtaButtonSettings({
            showButton: true,
            buttonText: doppeHideableValueCreateHidden('')
        }),
    }
}