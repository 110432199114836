import {doppeActionTypeDefineMetadata} from '../../client-server-common/doppe-action-types/doppe-action-type';
import {
    arrayFlatten,
    arrayRemoveNullValues,
    immerProduce,
    iterableMapToArray,
    PartiallyOptional
} from '@wix/devzai-utils-common';
import {wixMediaResourceReplaceExternalImage} from '@wix/devzai-common-wix';
import {
    convertCommonListSettingToDefaultDoppeActionCtaButtonSettingsFromListAction,
    DoppeActionLayout,
    doppeExternalEventProviderResolveBusinessEntityReference,
    DoppeHideableValue,
    doppeHideableValueCreateHidden,
    doppeHideableValueEnsureHidden,
    doppeHideableValueGetVisibleValue,
    doppeHideableValueIsHidden,
    doppeHideableValueIsVisibleAndNotEqualValue,
    doppeHideableValueModifyValue,
    doppeListActionGetDefaultListSettings, doppeListActionGetDefaultSearchListSettings,
    doppeListActionGetItemClickBehaviourDefaultListSettings,
    doppeListActionGetMainDetailsDefaultSettings,
    doppeListActionGetWithExternalDataSourceDefaultListSettings,
    doppeListActionListItemGetDefaultListItemSettingsMigration,
    DoppeListActionSettings, doppeListActionSettingsFilterHiddenListItemsFromActionView,
    doppeListActionWithMainDetailsGetMainImage,
    doppeListActionWithMainDetailsReplaceMainImage
} from '../../client-server-common';
import {
    convertDoppeWixEventToEventSettings,
    DoppeActionTypeEvent,
    populateEventActionSettingsDefaultTexts
} from '../doppe-action-type-event/doppe-action-type-event';
import {DoppeDtoEvent} from '../../client-server-common/types/doppe-dto-event';
import {
    DoppeEventsExternalDataSource,
    doppeEventsExternalDataSourceResolveBusinessEntityReferences
} from '../../client-server-common/types/doppe-events-external-data-source';
import {
    doppeDtoActionSettingsRemoveInnerCtaContentForLockedAction
} from '../../client-server-common/types/doppe-dto-action';
import {DoppeAppPageContentLocales} from '../../client/doppe-app-locales/doppe-app-page-content-locales';
import {
    doppeActionCtaButtonResetSettingsForLock,
    doppeActionCtaButtonTypeResolveURL
} from '../../client-server-common/types/doppe-action-cta-button';
import {
    doppeActionCtaButtonTypeGetDefaultCtaButtonSettingsMigration,
    getDefaultDoppeActionCtaButtonSettings
} from '../../client-server-common/types/doppe-action-cta-button-settings';
import {
    doppeActionSettingsCtaDefaultSettings
} from '../../client/doppe-action-types-client-common/doppe-action-settings-cta-client-common';


export namespace DoppeActionTypeEvents {

    export interface EventSettings extends
        DoppeListActionSettings.ListItemBaseSettings,
        DoppeActionTypeEvent.ActionSettings {
        eventTitle: string;
    }

    export interface ActionSettings extends
        DeprecatedFields,
        DoppeListActionSettings<EventSettings>,
        DoppeListActionSettings.WithSearchSettings,
        DoppeListActionSettings.WithMainDetails,
        DoppeListActionSettings.WithCtaButton,
        DoppeListActionSettings.WithItemClickBehaviour,
        DoppeListActionSettings.WithExternalDataSourceSupport<DoppeEventsExternalDataSource> {
        itemShowDate: boolean;
        previewShowTitle: boolean;
        previewShowDescription: boolean;
        previewShowCoupon: boolean;
        previewShowPrice: boolean;
        previewEventButtonText: string;
    }

    export interface DeprecatedFields {
        events?: EventSettings[];
    }
}


export const doppeActionTypeEvents = doppeActionTypeDefineMetadata<DoppeActionTypeEvents.ActionSettings>({
    id: 'io.bymo.action/events',
    resolveActionSettings: action => {

        const {
            listDataItems = [],
            listSearchSettings,
            ctaButton,
            ...restSettings
        } = action.settings;

        return {
            itemShowDate: true,
            previewShowDescription: true,
            previewShowTitle: true,
            previewShowCoupon: true,
            previewShowPrice: true,
            previewEventButtonText: '',
            ...doppeListActionGetWithExternalDataSourceDefaultListSettings(),
            ...doppeListActionGetDefaultSearchListSettings(action.settings),
            ...doppeListActionGetDefaultListSettings({
                layout: DoppeActionLayout.Grid,
                listDataItems: listDataItems.map(event => {

                    const {
                        eventButtonText,
                        ctaButton,
                        ...restEvent
                    } = event;

                    return {
                        eventProviderId: null,
                        eventProvider: null,
                        ribbon: doppeHideableValueCreateHidden(''),
                        ...getDefaultDoppeActionCtaButtonSettings({
                            buttonText: eventButtonText,
                            showButton: true
                        }),
                        ...doppeActionCtaButtonTypeGetDefaultCtaButtonSettingsMigration({
                            hasSourceURL: true,
                            ctaButton: ctaButton,
                        }),
                        ...doppeListActionListItemGetDefaultListItemSettingsMigration(event.id),
                        ...restEvent as PartiallyOptional<DoppeActionTypeEvents.EventSettings, 'eventProvider' | 'eventProviderId' | 'ribbon' | 'ctaButton'>
                    }
                }),
            }),
            ...doppeListActionGetItemClickBehaviourDefaultListSettings(),
            ...doppeListActionGetMainDetailsDefaultSettings(),
            ...convertCommonListSettingToDefaultDoppeActionCtaButtonSettingsFromListAction(action.settings),
            ...doppeActionCtaButtonTypeGetDefaultCtaButtonSettingsMigration({
                ctaButton: ctaButton,
                hasSourceURL: false
            }),
            ...restSettings
        }
    },
    isWidgetOpener: true,
    canRenderInPage: true,
    skipReportingOnGoalAchievedOnActionButtonClick: true,
    isListItemLinkOpener: true,
    supportsListLayout: true,
    resolveListItemsCount: actionSettings => {
        return actionSettings.externalDataSource ? actionSettings.externalDataSourceItemsCount : doppeListActionSettingsFilterHiddenListItemsFromActionView(actionSettings.listDataItems).length;
    },
    resolveCouponDataFromListItem: listItem => {
        return {
            showCouponCode: doppeHideableValueIsVisibleAndNotEqualValue(listItem.couponCode, ''),
            couponCode: doppeHideableValueGetVisibleValue(listItem.couponCode, ''),
            couponDescription: '',
            couponTitle: doppeHideableValueGetVisibleValue(listItem.title, ''),
        }
    },
    resolveListItems: action => {
        const actionSettings = action.settings;
        return actionSettings.listDataItems.map(item => {
            return {
                title: item.eventTitle,
                action: action,
                couponCode: item.couponCode,
                description: item.description,
                image: item.eventImages?.[0] ?? null,
                itemId: item.id,
                ribbon: item.ribbon,
                url: item.eventLink,
                enabled: item.enabled,
                deleted: item.deleted,
                displayInSearch: item.displayInSearch,
            }
        });
    },
    resolveListSearchSettings: actionSettings => {
        return actionSettings.listSearchSettings;
    },
    resolveUsedMedia: actionSettings => {
        return arrayRemoveNullValues([...arrayFlatten(actionSettings.listDataItems.map(itemSettings => {
            return itemSettings.eventImages ?? []
        })), doppeHideableValueIsVisibleAndNotEqualValue(actionSettings.mainImage, null) ? actionSettings.mainImage : null]);
    },
    resolveMainMedia: actionSettings => {
        return doppeListActionWithMainDetailsGetMainImage(actionSettings, actionSettings.listDataItems[0]?.eventImages?.[0] ?? null);
    },
    resolveMainLink: actionSettings => {
        return doppeActionCtaButtonTypeResolveURL(actionSettings.ctaButton.settings) ?? null;
    },
    prepareForViewer: (actionSettings, options) => {
        doppeDtoActionSettingsRemoveInnerCtaContentForLockedAction(actionSettings, actionSettings => {
            actionSettings.listDataItems.forEach(itemSettings => {
                itemSettings.eventLink = ''
                doppeActionCtaButtonResetSettingsForLock(itemSettings);
            });

            doppeActionCtaButtonResetSettingsForLock(actionSettings);
        }, options);
    },
    isConnectedToExternalDataSource: actionSettings => {
        return !!actionSettings.externalDataSource
    },
    resolveUsedBusinessEntities: actionSettings => {
        const externalDataSource = actionSettings.externalDataSource;
        if (externalDataSource) {
            return [
                doppeEventsExternalDataSourceResolveBusinessEntityReferences(externalDataSource)
            ];
        } else {
            return iterableMapToArray(actionSettings.listDataItems, (event, skip) => {
                return event.eventProvider !== null && event.eventProviderId !== null ?
                    doppeExternalEventProviderResolveBusinessEntityReference(
                        event.eventProvider,
                        event.eventProviderId
                    ) : skip
            });
        }
    },
    replaceExternalImages: (actionSettings, externalImagesUrlToImageResourceMap) => {
        return immerProduce(actionSettings, actionSettings => {
            for (const event of actionSettings.listDataItems) {
                if (event.eventImages) {
                    event.eventImages = event.eventImages.map(
                        image => wixMediaResourceReplaceExternalImage(image, externalImagesUrlToImageResourceMap))
                }
            }

            doppeListActionWithMainDetailsReplaceMainImage(actionSettings, externalImagesUrlToImageResourceMap);
        })
    },
});

export function populateEventsActionEventSettingsDefaultTexts(options: {
    event: DoppeActionTypeEvents.EventSettings,
    pageContentLocales: DoppeAppPageContentLocales,
}): DoppeActionTypeEvents.EventSettings {

    const {
        pageContentLocales,
        event,
    } = options;

    const normalizedContent = populateEventActionSettingsDefaultTexts({
        pageContentLocales: pageContentLocales,
        event: event
    });

    const defaultButtonText = doppeHideableValueModifyValue(normalizedContent.ctaButton.text, (value) => {
        return value ?? pageContentLocales["event.actionSettings.buttonText"]
    });

    const calculatedDefaultValue = doppeHideableValueEnsureHidden(defaultButtonText);

    return {
        ...event,
        ...normalizedContent,
        eventTitle: doppeHideableValueIsHidden(normalizedContent.eventTitle) ? '' : normalizedContent.eventTitle,
        eventButtonText: doppeHideableValueModifyValue(normalizedContent.eventButtonText, (value) => {
            return value ?? pageContentLocales['event.actionSettings.buttonText']
        }),
        ...doppeActionSettingsCtaDefaultSettings(pageContentLocales,{
            showButton: true,
            buttonText: calculatedDefaultValue
        })
    }
}

export function convertDoppeWixEventToEventsSettings(
    event: DoppeDtoEvent,
    settings?: {
        eventButtonText?: DoppeHideableValue<string>;
    }
): DoppeActionTypeEvents.EventSettings {
    return {
        ...convertDoppeWixEventToEventSettings(event),
        ...doppeListActionListItemGetDefaultListItemSettingsMigration(event.id),
        couponCode: doppeHideableValueCreateHidden(''),
        ribbon: doppeHideableValueCreateHidden(''),
        eventButtonText: settings?.eventButtonText ?? doppeHideableValueCreateHidden(''),
        ...getDefaultDoppeActionCtaButtonSettings({
            showButton: true,
            buttonText: doppeHideableValueCreateHidden('')
        }),
    }
}