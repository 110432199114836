import {doppeActionTypeDefineMetadata} from '../../client-server-common/doppe-action-types/doppe-action-type';
import {
    DoppeHideableValue,
    doppeHideableValueCreateHidden
} from '../../client-server-common/types/doppe-hideable-value';
import {wixMediaResourceReplaceExternalImage} from '@wix/devzai-common-wix';
import {immerProduce} from '@wix/devzai-utils-common';
import {BymoPageMedia, DoppeActionPriceData} from '../../client-server-common';
import {RichTextContent} from '@wix/devzai-utils-react';
import {
    doppeDtoActionSettingsRemoveInnerCtaContentForLockedAction
} from '../../client-server-common/types/doppe-dto-action';
import {
    doppeActionCtaButtonsResetSettingsForLock,
    doppeActionCtaButtonsResolveMainURL
} from '../../client-server-common/types/doppe-action-cta-button';
import {
    doppeActionContentSectionSettingsDefaultValuesMigration,
    DoppeActionContentSectionsSettings
} from '../../client-server-common/types/doppe-action-content-sections-settings';
import {
    doppeActionGetMediaViewDefaultSettingsMigration,
    DoppeActionMediaViewSettings
} from '../../client-server-common/types/doppe-action-media-view-settings';
import {
    DoppeActionCtaButtonsSettings,
    doppeActionCtaButtonsSettingsDefaultValuesMigration
} from '../../client-server-common/types/doppe-action-cta-buttons-settings';

export namespace DoppeActionTypeProperty {
    export interface ActionSettings extends
        DoppeActionContentSectionsSettings,
        DoppeActionCtaButtonsSettings,
        DoppeActionMediaViewSettings {
        propertyName: DoppeHideableValue<string>;
        description: DoppeHideableValue<RichTextContent>;
        media: BymoPageMedia[] | null;
        priceData: DoppeHideableValue<DoppeActionPriceData>;
        address: DoppeHideableValue<string>;
        ribbon: DoppeHideableValue<string>;
    }
}

export const doppeActionTypeProperty = doppeActionTypeDefineMetadata<DoppeActionTypeProperty.ActionSettings>({
    id: 'io.bymo.action/property',
    resolveActionSettings: action => {

        const {
            sections,
            ctaButtons,
            mainMediaViewSettings,
            mainMediaViewLayout,
            ...restSettings
        } = action.settings;

        return {
            priceData: {
                actualPrice: '',
            },
            propertyName: doppeHideableValueCreateHidden(''),
            description:  doppeHideableValueCreateHidden(''),
            address: doppeHideableValueCreateHidden(''),
            ribbon: doppeHideableValueCreateHidden(''),
            media: [],
            ...doppeActionGetMediaViewDefaultSettingsMigration(action.settings),
            ...doppeActionContentSectionSettingsDefaultValuesMigration(action.settings),
            ...doppeActionCtaButtonsSettingsDefaultValuesMigration({
                ctaButtons: ctaButtons,
            }, false),
            ...restSettings
        }
    },
    isWidgetOpener: true,
    isLinkOpener: false,
    canRenderInPage: true,
    skipReportingOnGoalAchievedOnActionButtonClick: true,
    resolveUsedMedia: actionSettings => {
        return actionSettings.media ?? [];
    },
    resolveMainMedia: actionSettings => {
        return actionSettings.media ? actionSettings.media[0] ?? null : null;
    },
    replaceExternalImages: (actionSettings, externalImagesUrlToImageResourceMap) => {
        return immerProduce(actionSettings, actionSettings => {
            if (actionSettings.media) {
                actionSettings.media = actionSettings.media.map(
                    image => wixMediaResourceReplaceExternalImage(image, externalImagesUrlToImageResourceMap))
            }
        })
    },
    prepareForViewer: (actionSettings, options) => {
        doppeDtoActionSettingsRemoveInnerCtaContentForLockedAction(actionSettings, actionSettings => {
            doppeActionCtaButtonsResetSettingsForLock(actionSettings);
        }, options);
    },
    resolveMainLink: actionSettings => {
        return doppeActionCtaButtonsResolveMainURL(actionSettings) ?? null;
    },
});


