import {doppeActionTypeDefineMetadata} from '../../client-server-common/doppe-action-types/doppe-action-type';
import {
    arrayFlatten,
    arrayRemoveNullValues,
    immerProduce,
    iterableMapToArray,
    PartiallyOptional
} from '@wix/devzai-utils-common';
import {
    convertDoppeWixBlogPostToBlogPostSettings,
    DoppeActionTypeBlogPost,
    populateBlogPostActionSettingsDefaultTexts
} from '../doppe-action-type-blog-post/doppe-action-type-blog-post';
import {wixMediaResourceReplaceExternalImage} from '@wix/devzai-common-wix';
import {
    convertCommonListSettingToDefaultDoppeActionCtaButtonSettingsFromListAction,
    DoppeBlogPostsExternalDataSource,
    doppeBlogPostsExternalDataSourceResolveBusinessEntityReferences,
    doppeExternalBlogPostProviderResolveBusinessEntityReference,
    doppeHideableValueEnsureHidden,
    doppeHideableValueIsHidden,
    doppeHideableValueIsVisibleAndNotEqualValue,
    doppeHideableValueModifyValue,
    doppeListActionGetDefaultListSettings, doppeListActionGetDefaultSearchListSettings,
    doppeListActionGetItemClickBehaviourDefaultListSettings,
    doppeListActionGetMainDetailsDefaultSettings,
    doppeListActionGetWithExternalDataSourceDefaultListSettings,
    doppeListActionListItemGetDefaultListItemSettingsMigration,
    DoppeListActionSettings, doppeListActionSettingsFilterHiddenListItemsFromActionView,
    doppeListActionWithMainDetailsGetMainImage,
    doppeListActionWithMainDetailsReplaceMainImage
} from '../../client-server-common';
import {DoppeDtoBlogPost} from '../../client-server-common/types/doppe-dto-blog-post';
import {doppeHideableValueCreateHidden} from '../../client-server-common/types/doppe-hideable-value';
import {
    doppeDtoActionSettingsRemoveInnerCtaContentForLockedAction
} from '../../client-server-common/types/doppe-dto-action';
import {DoppeAppPageContentLocales} from '../../client/doppe-app-locales/doppe-app-page-content-locales';
import {
    doppeActionCtaButtonResetSettingsForLock,
    doppeActionCtaButtonTypeResolveURL
} from '../../client-server-common/types/doppe-action-cta-button';
import {
    doppeActionSettingsCtaDefaultSettings
} from '../../client/doppe-action-types-client-common/doppe-action-settings-cta-client-common';
import {
    doppeActionCtaButtonTypeGetDefaultCtaButtonSettingsMigration,
    getDefaultDoppeActionCtaButtonSettings
} from '../../client-server-common/types/doppe-action-cta-button-settings';


export namespace DoppeActionTypeArticlesList {

    export interface ArticleSettings extends
        DoppeListActionSettings.ListItemBaseSettings,
        DoppeActionTypeBlogPost.ActionSettings
    {
        blogPostTitle: string;
    }

    export interface ActionSettings extends
        DeprecatedFields,
        DoppeListActionSettings<ArticleSettings>,
        DoppeListActionSettings.WithSearchSettings,
        DoppeListActionSettings.WithCtaButton,
        DoppeListActionSettings.WithMainDetails,
        DoppeListActionSettings.WithItemClickBehaviour,
        DoppeListActionSettings.WithExternalDataSourceSupport<DoppeBlogPostsExternalDataSource>
    {
        previewShowTitle: boolean;
        previewShowDescription: boolean;
        previewShowBlogPostDate: boolean;
        previewShowBlogWebsite: boolean;
        previewBlogPostButtonText: string;
    }

    export interface DeprecatedFields {
        articles?: ArticleSettings[];
    }
}


export const doppeActionTypeArticlesList = doppeActionTypeDefineMetadata<DoppeActionTypeArticlesList.ActionSettings>({
    id: 'io.bymo.action/articles-list',
    resolveActionSettings: action => {

        const {
            listDataItems = [],
            ctaButton,
            listSearchSettings,
            ...restSettings
        } = action.settings;

        return {
            previewShowBlogPostDate: true,
            previewShowBlogWebsite: true,
            previewShowDescription: true,
            previewShowTitle: true,
            previewBlogPostButtonText: '',
            ...doppeListActionGetWithExternalDataSourceDefaultListSettings(),
            ...doppeListActionGetDefaultSearchListSettings(action.settings),
            ...doppeListActionGetDefaultListSettings({
                listDataItems: listDataItems.map(article => {

                    const {
                        blogPostButtonText,
                        ctaButton,
                        ...restArticle
                    } = article;

                    return {
                        blogPostProvider: null,
                        blogPostProviderId: null,
                        ...getDefaultDoppeActionCtaButtonSettings({
                            buttonText: blogPostButtonText,
                            showButton: true
                        }),
                        ...doppeActionCtaButtonTypeGetDefaultCtaButtonSettingsMigration({
                            hasSourceURL: true,
                            ctaButton: ctaButton,
                        }),
                        ...doppeListActionListItemGetDefaultListItemSettingsMigration(article.id),
                        ...restArticle as PartiallyOptional<DoppeActionTypeArticlesList.ArticleSettings, 'blogPostProvider' | 'blogPostProviderId' | 'ctaButton'>,
                    };
                }) ?? [],
            }),
            ...doppeListActionGetItemClickBehaviourDefaultListSettings(),
            ...doppeListActionGetMainDetailsDefaultSettings({
                defaultTitle: ''
            }),
            ...convertCommonListSettingToDefaultDoppeActionCtaButtonSettingsFromListAction(action.settings),
            ...doppeActionCtaButtonTypeGetDefaultCtaButtonSettingsMigration({
                ctaButton: ctaButton,
                hasSourceURL: false
            }),
            ...restSettings
        }
    },
    isWidgetOpener: true,
    canRenderInPage: true,
    skipReportingOnGoalAchievedOnActionButtonClick: true,
    isListItemLinkOpener: true,
    supportsListLayout: true,
    prepareForViewer: (actionSettings, options) => {
        doppeDtoActionSettingsRemoveInnerCtaContentForLockedAction(actionSettings, actionSettings => {
            actionSettings.listDataItems.forEach(article => {
                article.blogPostLink = '';
                doppeActionCtaButtonResetSettingsForLock(article);
            });

            doppeActionCtaButtonResetSettingsForLock(actionSettings);
        }, options);
    },
    resolveUsedMedia: actionSettings => {
        return arrayRemoveNullValues([...arrayFlatten(actionSettings.listDataItems.map(article => {
            return article.blogPostImages ?? []
        })), doppeHideableValueIsVisibleAndNotEqualValue(actionSettings.mainImage, null) ? actionSettings.mainImage : null]);
    },
    resolveMainMedia: actionSettings => {
        return doppeListActionWithMainDetailsGetMainImage(actionSettings, actionSettings.listDataItems[0]?.blogPostImages?.[0] ?? null);
    },
    resolveMainLink: actionSettings => {
        return doppeActionCtaButtonTypeResolveURL(actionSettings.ctaButton.settings) ?? null;
    },
    isConnectedToExternalDataSource: actionSettings => {
        return !!actionSettings.externalDataSource
    },
    resolveUsedBusinessEntities: actionSettings => {
        const externalDataSource = actionSettings.externalDataSource;
        if (externalDataSource) {
            return [
                doppeBlogPostsExternalDataSourceResolveBusinessEntityReferences(externalDataSource)
            ];
        } else {
            return iterableMapToArray(actionSettings.listDataItems, (article, skip) => {
                return article.blogPostProvider !== null && article.blogPostProviderId !== null ?
                    doppeExternalBlogPostProviderResolveBusinessEntityReference(
                        article.blogPostProvider,
                        article.blogPostProviderId
                    ) : skip
            });
        }
    },
    resolveListItemsCount: actionSettings => {
        return actionSettings.externalDataSource ? actionSettings.externalDataSourceItemsCount : doppeListActionSettingsFilterHiddenListItemsFromActionView(actionSettings.listDataItems).length;
    },
    resolveListItems: action => {
        const actionSettings = action.settings;

        return actionSettings.listDataItems.map(article => {
            return {
                title: article.blogPostTitle,
                action: action,
                couponCode: doppeHideableValueCreateHidden(''),
                description: article.description,
                image: article.blogPostImages?.[0] ?? null,
                itemId: article.id,
                ribbon: doppeHideableValueCreateHidden(''),
                url: article.blogPostLink,
                enabled: article.enabled,
                deleted: article.deleted,
                displayInSearch: article.displayInSearch,
            }
        });
    },
    resolveListSearchSettings: actionSettings => {
        return actionSettings.listSearchSettings;
    },
    replaceExternalImages: (actionSettings, externalImagesUrlToImageResourceMap) => {
        return immerProduce(actionSettings, actionSettings => {
            for (const article of actionSettings.listDataItems) {
                if (article.blogPostImages) {
                    article.blogPostImages = article.blogPostImages.map(
                        image => wixMediaResourceReplaceExternalImage(image, externalImagesUrlToImageResourceMap))
                }
            }

            doppeListActionWithMainDetailsReplaceMainImage(actionSettings, externalImagesUrlToImageResourceMap);
        })
    },
});

export function populateBlogPostsActionPostSettingsDefaultTexts(options: {
    blogPost: DoppeActionTypeArticlesList.ArticleSettings,
    pageContentLocales: DoppeAppPageContentLocales,
}) : DoppeActionTypeArticlesList.ArticleSettings {

    const {
        pageContentLocales,
        blogPost,
    } = options;

    const normalizedBlogPost = populateBlogPostActionSettingsDefaultTexts({
        pageContentLocales: pageContentLocales,
        blogPost: blogPost
    })

    const defaultButtonText = doppeHideableValueModifyValue(normalizedBlogPost.ctaButton.text, (value) => {
        return value ?? pageContentLocales["blogPost.actionSettings.buttonText"]
    });

    const calculatedDefaultValue = doppeHideableValueEnsureHidden(defaultButtonText);

    return {
        ...blogPost,
        ...normalizedBlogPost,
        blogPostTitle: doppeHideableValueIsHidden(normalizedBlogPost.blogPostTitle) ? '' : normalizedBlogPost.blogPostTitle,
        blogPostButtonText: calculatedDefaultValue,
        ...doppeActionSettingsCtaDefaultSettings(pageContentLocales,{
            showButton: true,
            buttonText: calculatedDefaultValue
        })
    }
}

export function convertDoppeWixBlogPostToBlogPostsSettings(blogPost: DoppeDtoBlogPost): DoppeActionTypeArticlesList.ArticleSettings {

    const blogPostSettings = convertDoppeWixBlogPostToBlogPostSettings(blogPost);

    return {
        ...blogPostSettings,
        blogPostTitle: doppeHideableValueIsHidden(blogPostSettings.blogPostTitle) ? '' : blogPostSettings.blogPostTitle,
        blogPostButtonText: doppeHideableValueCreateHidden(''),
        ...getDefaultDoppeActionCtaButtonSettings({
            showButton: true,
            buttonText: doppeHideableValueCreateHidden('')
        }),
        ...doppeListActionListItemGetDefaultListItemSettingsMigration(blogPost.id),
    }

}